<template>
    <div>
        <b-sidebar backdrop id="cardApprove" width="75rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right  shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.approve_card}}</span>
                </div>
                <div @click="hide" id="closeMeNow" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>

            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-simple-table>
                        <thead>
                            <tr v-if="customer.cctype==1">
                                <th class="text-center backBlack" style="width:100px;">{{ lang.customer_type }}</th>
                                <td class="text-center">{{ lang.individual_client }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.mobile }}</th>
                                <td class="text-center">{{ customer.mobile }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.full_name }}</th>
                                <td class="text-center">{{ customer.full_name }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.owner_name }}</th>
                                <td class="text-center">{{ owner_name }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.id_number }}</th>
                                <td class="text-center">{{ customer.id_number }}</td>
                            </tr>
                            <tr v-if="customer.cctype==2">
                                <th class="text-center backBlack" style="width:100px;">{{ lang.customer_type }}</th>
                                <td class="text-center">{{ lang.company_clinet }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.mobile }}</th>
                                <td class="text-center">{{ customer.mobile }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.company_name }}</th>
                                <td class="text-center">{{ company.fullname }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.company_vatid }}</th>
                                <td class="text-center">{{ company.vatid }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.crt_number }}</th>
                                <td class="text-center">{{ company.crt_number }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.building_no }}</th>
                                <td class="text-center">{{ company.building_no }}</td>
                            </tr>
                            <tr v-if="customer.cctype==2">
                                <th class="text-center backBlack" style="width:100px;">{{ lang.street_name }}</th>
                                <td class="text-center">{{ company.street_name }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.branch_number }}</th>
                                <td class="text-center">{{ company.branch_number }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.zipcode }}</th>
                                <td class="text-center">{{ company.zipcode }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.district }}</th>
                                <td class="text-center">{{ company.district }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.city }}</th>
                                <td class="text-center">{{ company.city }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.comapny_address }}</th>
                                <td class="text-center">{{ company.address }}</td>
                            </tr>
                            <tr v-if="customer.cctype==3">
                                <th class="text-center backBlack" style="width:100px;">{{ lang.entity_name }}</th>
                                <td class="text-center">{{ lang.entity_name }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.branch_name }}</th>
                                <td class="text-center">{{ customer.branch_name }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.order_number }}</th>
                                <td class="text-center">{{ company.order_number }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.po_number }}</th>
                                <td class="text-center">{{ company.po_number }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.po_date }}</th>
                                <td class="text-center">{{ company.po_date }}</td>
                            </tr>
                        </thead>
                    </v-simple-table>
                </v-row>
                <v-row>
                    
                    <v-col cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.total }}</th>
                                    <th class="text-center backBlack">{{ lang.vat }}</th>
                                    <th class="text-center backBlack">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack">{{ lang.paid }}</th>
                                    <th class="text-center backBlack">{{ lang.remain }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="text-center">{{ full_total }}</th>
                                    <th class="text-center">{{ full_vat }}</th>
                                    <th class="text-center">{{ full_ftotal }}</th>
                                    <th class="text-center">{{ (!invoice.payment.cash || invoice.payment.cash == '') ? 0 : invoice.payment.cash }}</th>
                                    <th class="text-center">{{ remain }}</th>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow" v-if="showButtomSubmit">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='createCard()' class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                <b-button type="button" variant="danger" @click='cancel()' class="ma-2" style="width:100px;">{{lang.cancel}}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            print_type: 1,
            contract_id: 0,
            work_type: 1,
            owner_name: "",
            terms: '',
            showButtomSubmit: true,
            carStatus: [],
            images: [],
            payby: '',
            itemsworkdes:[],
            cars: [],
            date: '',
            received_date: '',
            delivery_date: '',
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            superVisor: 0,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
                order_number: '',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                id_number: 1,
            },
            invoice:{
                received_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: '',
                    span: ''
                },
                delivery_date: '',
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                next_oil_change: '',
                
                invtype:2,
                
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
                card_type: 1,
                old_invoice: ''
            },
            tbrows:[
            ],
            workersList: [],
            customersList: [],
            attached:[]
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        plates: function(){
            let t = {}
            for(let i=0;i<this.tbrows.length;i++){
                t[this.tbrows[i].plate_number] = this.tbrows[i];
            }
            return t;
        },
        remain: function(){
            const tt = (!this.invoice.payment.cash || this.invoice.payment.cash == '') ? 0 : this.invoice.payment.cash
            let t = parseFloat(this.full_ftotal) - parseFloat(tt);
            return t;
        },
        cimages: function(){
            let t = [];
            for(let i=0;i<this.cars.length;i++){
                let test = false;
                for(let j=0;j<this.images.length;j++){
                    if(this.cars[i].plate_number == ''){
                        test = true;
                        continue;
                    }
                    if(this.cars[i].plate_number == this.images[j].plate){
                        t.push({
                            image: this.images[j].image,
                            plate: this.images[j].plate
                        })
                        test = true;
                    }
                }
                if(!test){
                    t.push({
                        image: './img/car-background.png',
                        plate: this.cars[i].plate_number
                    })
                }
            }
            return t;
        }
    },
    methods: {
        modifyIt(note){
            // console.log(note)
            this.print_type = note.print_type,
            this.contract_id = note.contract_id,
            this.customer_name = note.customer_name,
            this.customer_afforded = note.customer_afforded,
            this.owner_name = note.owner_name,
            this.owner_name = note.owner_name,
            this.carStatus = note.carStatus,
            this.customersList = note.customersList
            this.workersList = note.workersList
            this.tbrows = note.tbrows
            this.invoice = note.invoice
            this.customer = note.customer
            this.company = note.company
            this.full_ftotal = note.full_ftotal
            this.full_vat = note.full_vat
            this.full_total = note.full_total
            this.delivery_date = note.delivery_date
            this.received_date = note.received_date
            this.date = note.date
            this.cars = note.cars
            this.itemsworkdes = note.itemsworkdes
            this.payby = note.payby
            this.images = note.images
            this.attached = note.attached
            this.terms = note.terms
            this.superVisor = note.superVisor
            // // console.log(note);
            // // console.log(this.attached);
        },
        
        async createCard(){
            this.showButtomSubmit = false
            const post = new FormData();
            post.append("type" , "addNewCard"); 
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i=0;i<this.carStatus.length;i++){
                post.append('data[carStatus]['+i+'][description]',this.carStatus[i].description)
                post.append('data[carStatus]['+i+'][plate]',this.carStatus[i].plate)
            }
            
            for(let i=0;i<this.terms.length;i++){
                post.append('data[terms]['+i+']',this.terms[i])
            }
            for(let i=0;i<this.tbrows.length;i++){
                let d = '';
                Object.values(this.tbrows[i].workers_name).forEach(value => {
                    d += (d == '' ? "" : ",") + value;
                })
                post.append('data[tbrows]['+i+'][workers_name]',d)
                post.append('data[tbrows]['+i+'][vat]',this.tbrows[i].vat)
                post.append('data[tbrows]['+i+'][total]',this.tbrows[i].total)
                post.append('data[tbrows]['+i+'][qty]',this.tbrows[i].qty)
                post.append('data[tbrows]['+i+'][plate_number]',this.tbrows[i].plate_number)
                post.append('data[tbrows]['+i+'][itemcode]',this.tbrows[i].itemcode)
                post.append('data[tbrows]['+i+'][item_price]',this.tbrows[i].item_price)
                post.append('data[tbrows]['+i+'][item_name]',this.tbrows[i].item_name)
                post.append('data[tbrows]['+i+'][ftotal]',this.tbrows[i].ftotal)
                post.append('data[tbrows]['+i+'][unitid]',this.tbrows[i].unitid)
            }
            post.append('data[invoice][invtype]',this.invoice.invtype);
            post.append('data[invoice][notes]',this.invoice.notes);
            post.append('data[invoice][payment]',this.invoice.payment.cash);
            post.append('data[invoice][paytype]',this.invoice.paytype);
            post.append('data[invoice][t_discount]',this.invoice.t_discount);
            post.append('data[invoice][userid]',this.invoice.userid);
            post.append('data[invoice][vtype]',this.invoice.vtype);
            post.append('data[invoice][superVisor]',this.superVisor);
            post.append('data[invoice][card_type]',this.invoice.card_type);
            post.append('data[invoice][old_invoice]',this.invoice.old_invoice);

            post.append('data[customer_name]',this.customer_name);
            post.append('data[contract_id]',this.contract_id);
            post.append('data[customer_afforded]',this.customer_afforded);
            post.append('data[work_type]',this.work_type);
            post.append('data[owner_name]',this.owner_name);
            post.append('data[payby]',this.payby);
            post.append('data[delivery_date]',this.delivery_date);
            post.append('data[full_ftotal]',this.full_ftotal);
            post.append('data[full_total]',this.full_total);
            post.append('data[full_vat]',this.full_vat);
            post.append('data[note]',this.note);
            post.append('data[received_date]',this.received_date);
            for(let i=0;i<this.attached.length;i++){
                post.append('data[files]['+i+']',this.attached[i]);
            }
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post)
            this.showButtomSubmit = true
            if(response.data.results.data.length == 0){
                let message = this.lang.all_field_require;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }else{
                let message = this.lang.added_successfuly;
                this.$snotify.success(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('HideMe').click();
                document.getElementById('closeMeNow').click();
                this.$parent.$parent.$parent.closeIT();
                this.$parent.$parent.$parent.$parent.getCurrentCards();
                
                return false;
            }
        },
        cancel(){
            document.getElementById('closeMeNow').click();
        }
    },
}
</script>
<style>
.flexIt{
    display:flex;
    flex-direction: row;
    padding:5px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    justify-items: center;
    align-items: cente;
}
</style>